"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldPolyfill = void 0;
/**
 * https://bugs.chromium.org/p/chromium/issues/detail?id=1097432
 */
function hasMissingICUBug() {
    var DisplayNames = Intl.DisplayNames;
    if (DisplayNames && !DisplayNames.polyfilled) {
        return (new DisplayNames(['en'], {
            type: 'region',
        }).of('CA') === 'CA');
    }
    return false;
}
/**
 * https://bugs.chromium.org/p/chromium/issues/detail?id=1176979
 */
function hasScriptBug() {
    var DisplayNames = Intl.DisplayNames;
    if (DisplayNames && !DisplayNames.polyfilled) {
        return (new DisplayNames(['en'], {
            type: 'script',
        }).of('arab') !== 'Arabic');
    }
    return false;
}
function shouldPolyfill() {
    return !Intl.DisplayNames || hasMissingICUBug() || hasScriptBug();
}
exports.shouldPolyfill = shouldPolyfill;
